<template>
  <div class="container mt-4">
    <div v-if="loading" class="d-flex justify-content-center my-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Yükleniyor...</span>
      </div>
    </div>

    <!-- Mevcut Resimleri Göster -->
    <div v-if="images.length" class="mb-4">
      <h3>Eklenen Görseller</h3>
      <div class="d-flex flex-wrap">
        <div v-for="(image, index) in images" :key="index"
          class="image-container position-relative d-inline-block mx-2 mb-2">
          <a :href="image.url" target="_blank">
            <img :src="image.url" alt="Uploaded Image" class="img-thumbnail" />
          </a>
          <button type="button" @click="toggleFeatured(index)"
            :class="{ 'btn-warning': image.featured, 'btn-secondary': !image.featured }"
            class="btn position-absolute top-0 end-0 m-2">
            ★
          </button>
          <button type="button" @click="removeImage(index)" class="btn btn-danger position-absolute bottom-0 end-0 m-2">
            ×
          </button>
        </div>
      </div>
    </div>

    <!-- Dosya Yükleme Butonu ve Önizleme -->
    <div class="upload-container">
      <label for="file-upload" class="btn btn-primary" :class="{ 'disabled': images.length >= maxImages }">Görsel
        Yükle</label>
      <input id="file-upload" type="file" @change="handleFileUpload" multiple class="d-none"
        :disabled="images.length >= maxImages" />

    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, defineProps, defineEmits } from 'vue';
import axios from 'axios';

interface Image {
  url: string;
  featured: boolean;
}

// Props
const props = defineProps<{
  modelValue?: Image[];
  apiUrl: string;
  maxImages?: number;
}>();

// Emits
const emit = defineEmits<{
  (event: 'update:modelValue', images: Image[]): void;
}>();

const maxImages = props.maxImages || 10;
const images = ref<Image[]>(props.modelValue || []);
const loading = ref<boolean>(false);
const filePreviews = ref<string[]>([]);
const uploadingImages = ref<File[]>([]);

watch(() => props.modelValue, (newValue) => {
  images.value = newValue || [];
});

const handleFileUpload = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    loading.value = true;
    filePreviews.value = [];

    const files = Array.from(target.files).slice(0, maxImages - images.value.length);
    const uploadPromises = files.map(file => uploadImage(file));

    try {
      const uploadedFileNames = await Promise.all(uploadPromises);
      const newImages = uploadedFileNames.map((name) => ({
        url: `${name}`, // URL oluşturma
        featured: false
      }));

      filePreviews.value = newImages.map(image => image.url);
      images.value.push(...newImages);
      emit('update:modelValue', images.value);
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      loading.value = false;
    }
  }
};

const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append('image', file);

  uploadingImages.value.push(file);
  try {
    const response = await axios.post(props.apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data.name;
  } finally {
    uploadingImages.value = uploadingImages.value.filter(f => f !== file);
  }
};

const toggleFeatured = (index: number) => {
  const currentFeatured = images.value.find(image => image.featured);
  if (currentFeatured) {
    currentFeatured.featured = false; // Önceki öne çıkan görselin işaretini kaldır
  }

  images.value[index].featured = !images.value[index].featured;
  emit('update:modelValue', images.value);
};

const removeImage = (index: number) => {
  images.value.splice(index, 1);
  emit('update:modelValue', images.value);
};
</script>

<style scoped>
.image-container,
.preview-container {
  position: relative;
  display: inline-block;
  width: 150px;
  /* Standart boyut */
  height: 150px;
  /* Standart boyut */
  margin: 5px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-warning {
  color: gold;
  border-color: gold;
}

.btn-secondary {
  color: gray;
  border-color: gray;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#file-upload {
  display: none;
}

label[for="file-upload"] {
  cursor: pointer;
}

.img-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
