<template>
    <div class="container">
        <div class="form-g-wrapper">
            <div class="inner-container">
                <div class="d-flex align-items-center justify-content-end gap-5">
                    <div class="d-flex align-items-center justify-content-between gap-1">
                        <button class="new-btn btn btn-primary w-100" @click="router.push({ name: 'SafeCreate' })">
                         <i class="fa fa-solid fa-plus"></i>
                         Kasa Ekle
                        </button>
                        <!-- 
                        <button class="collection-btn fill-btn btn-g border-0 text-uppercase">banka Ekle</button>
                        -->
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width:70px;"></th>
                            <th scope="col" class="text-uppercase">hesap ismi</th>
                            <th scope="col" class="text-uppercase text-end">iban</th>
                            <th scope="col" class="text-uppercase text-end">döviz cinsi</th>
                            <th scope="col" class="text-uppercase text-end">
                                bakiye
                                <i class="fa-solid fa-arrow-down-a-z"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="safe in safes" :key="safe.id" style="cursor:pointer">
                            <th scope="row">
                                <div class="d-flex align-items-center justify-content-center table-icon-box">
                                    <i class="fa-solid fa-vault"></i>
                                </div>
                            </th>

                            <td>{{ safe.name }}</td>
                            <td class="text-end"></td>
                            <td class="text-end">{{ safe.currency?.code }}</td>
                            <td class="text-end text-danger" v-if="safe.balance < 0 ">{{ safe.balance }} {{ safe.currency?.symbol }}</td>
                            <td class="text-end text-success" v-if="safe.balance > 0 ">{{ safe.balance }} {{ safe.currency?.symbol }}</td>
                            <td class="text-end" v-if="safe.balance == 0 ">{{ safe.balance }} {{ safe.currency?.symbol }}</td>
                            <td class="text-end">
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-secondary btn-active-light-primary show menu-dropdown"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>
                                            <i class="fa-solid fa-ellipsis"></i>
                                        </span></button>
                                    <ul class="dropdown-menu fs-4 " aria-labelledby="speedBoxDropdown" style="">
                                        <li>
                                            <router-link class="dropdown-item"
                                                :to="{ name: 'SafeDetail', params: { id: safe.id } }">
                                                <i class="fa-solid fa-eye me-2"></i>
                                                Görüntüle
                                            </router-link>
                                            <router-link class="dropdown-item"
                                                :to="{ name: 'SafeEdit', params: { id: safe.id } }">
                                                <i class="fa-solid fa-edit me-2"></i>
                                                Düzenle
                                            </router-link>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <PageNavigator :totalPages="totalPages" :currentPage="page" @pageChanged="changePage" />

            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, Ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getSafes } from '@/services/SafesService';
import { Safes } from '@/types/Safes';
import PageNavigator from '@/components/PageNavigator.vue';
import { handleApiError } from '@/helpers/errorHandling';

const router = useRouter();
const safes: Ref<Safes[]> = ref([]);
const page = ref(1);
const pageSize = ref(10);
const totalPages = ref(1)
const search = ref('')

const fetchSafes = async () => {
    try {
        const data = await getSafes({ page: page.value, pageSize: pageSize.value, search: search.value });
        safes.value = data.data;
        totalPages.value = data.totalPages;
    } catch (error) {
        handleApiError(error);
    }
};
const changePage = async (page: any) => {
    page.value = page;
    await fetchSafes();
};

onMounted(async () => {
    await fetchSafes();
});


</script>

<style scoped>
.dropdown-menu {
    min-width: 50px;

}

.dropdown-item {
    color: #999593;
    font-weight: 700;
    font-size: 13px;
    padding: 5px 15px;
}

.dropdown-toggle::after {
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}
</style>
