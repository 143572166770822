export const ReportsRoutes = [
    {
        path: "/reports/sales",
        name: "salesReport",
        component: () => import("@/views/Reports/SalesReports.vue"),
    },
    {
        path: "/reports/purchases",
        name: "purchaseReport",
        component: () => import("@/views/Reports/PurchasesReports.vue"),
    }
]