<template>
  <!-- left-side -->
  <div class="col-lg-2 left-side-wrap">
    <div class="d-flex justify-content-center mb-5">
      <img src="../assets/images/logo.png" alt="user" class="logo" />
    </div>
    <aside class="left-side">
      <!-- user -->

      <div class="user d-flex">
        <img src="../assets/images/icons/user.svg" alt="user" v-if="!user.company.logo" />
        <img :src="user.company.logo" alt="user" class="companyLogo" v-else/>
        <div class="user-name-wrap d-flex flex-column ms-3">
          <span class="greeting">Merhaba,</span>
          <span class="user-name">{{ user.name }}</span>
          <span class="user-id">ID:{{ user.public_id }}</span>
        </div>
        <button class="close d-lg-none ms-auto me-5">
          <img src="../assets/images/icons/close-2.png" alt="" />
        </button>
      </div>
      <!-- menu -->

      <div class="menu-warp">
        <div class="mix-menu">
          <ul>
            <li class="menu-list d-flex align-items-center">
              <span class="icon home-icon d-flex align-items-center justify-content-center me-3"><img
                  src="../assets/images/icons/Home.svg" alt="home-icon" /></span>
              <router-link to="/" class="menu-link active"> Gösterge Paneli </router-link>
            </li>
          </ul>
        </div>

        <div class="accordion accordion-flush" id="actMenu">
          <div class="accordion-item">
            <h2 class="accordion-header d-flex align-items-center justify-content-between">
              <button class="accordion-button collapsed upmenu-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#act-collapse" aria-expanded="false" aria-controls="act-collapse">
                <div class="menu-list d-flex align-items-center">
                  <span class="icon calendar-icon d-flex align-items-center justify-content-center me-3"><img
                      src="../assets/images/icons/Calendar.svg" alt="calendar-icon" /></span>
                  <span class="menu-link"> Satışlar </span>
                </div>
              </button>
            </h2>
            <div id="act-collapse" class="accordion-collapse collapse pages-menu" data-bs-parent="#actMenu">
              <!-- acordion   -->
              <ul>
                <li class="menu-list d-flex align-items-center alt-menu menu-item">
                  <router-link to="/customers" class="menu-link">Müşteriler</router-link>
                </li>
                <li class="menu-list d-flex align-items-center alt-menu menu-item">
                  <router-link to="/sale-invoice" class="menu-link">Satış Faturaları</router-link>
                </li>
                <li class="menu-list d-flex align-items-center alt-menu menu-item">
                  <router-link to="/purchase-invoice" class="menu-link">Alış Faturaları</router-link>
                </li>
                <li class="menu-list d-flex align-items-center alt-menu menu-item">
                  <router-link to="/return-invoice" class="menu-link">İade Faturaları</router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/customer-groups" class="menu-link">
                    Müşteri Grupları
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush" id="pages">
          <div class="accordion-item">
            <h2 class="accordion-header d-flex align-items-center justify-content-between">
              <button class="accordion-button collapsed upmenu-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#pagesCollapse" aria-expanded="false" aria-controls="act-collapse">
                <div class="menu-list d-flex align-items-center">
                  <span class="icon pages-icon d-flex align-items-center justify-content-center me-3"><img
                      src="../assets/images/icons/copy-2.svg" alt="pages-icon" /></span>
                  <span class="menu-link"> Nakit </span>
                </div>
              </button>
            </h2>
            <div id="pagesCollapse" class="accordion-collapse collapse pages-menu" data-bs-parent="#pages">
              <ul>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/safes" class="menu-link">
                    Kasalar
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush" id="stocks">
          <div class="accordion-item">
            <h2 class="accordion-header d-flex align-items-center justify-content-between">
              <button class="accordion-button collapsed upmenu-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#stocksCollapse" aria-expanded="false" aria-controls="act-collapse">
                <div class="menu-list d-flex align-items-center">
                  <span class="icon pages-icon d-flex align-items-center justify-content-center me-3"><img
                      src="../assets/images/icons/copy-2.svg" alt="pages-icon" /></span>
                  <span class="menu-link"> Stok </span>
                </div>
              </button>
            </h2>
            <div id="stocksCollapse" class="accordion-collapse collapse pages-menu" data-bs-parent="#pages">
              <ul>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/products" class="menu-link">
                    Ürünler
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/products-categories" class="menu-link">
                    Kategoriler
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/brands" class="menu-link">
                    Markalar
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/models" class="menu-link">
                    Modeller
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/warehouses" class="menu-link">Depolar</router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/shelves" class="menu-link">Raflar</router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/warehouses/transfer" class="menu-link">Depolar Arası Transfer</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush" id="reports">
          <div class="accordion-item">
            <h2 class="accordion-header d-flex align-items-center justify-content-between">
              <button class="accordion-button collapsed upmenu-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#reportsCollapse" aria-expanded="false" aria-controls="act-collapse">
                <div class="menu-list d-flex align-items-center">
                  <span class="icon pages-icon d-flex align-items-center justify-content-center me-3"><img
                      src="../assets/images/icons/copy-2.svg" alt="pages-icon" /></span>
                  <span class="menu-link"> Raporlar </span>
                </div>
              </button>
            </h2>
            <div id="reportsCollapse" class="accordion-collapse collapse pages-menu" data-bs-parent="#pages">
              <ul>
               
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/reports/sales" class="menu-link">
                    Satış Raporları
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/reports/purchases" class="menu-link">
                    Alış Raporları
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/models" class="menu-link">
                    KDV Raporu
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush" id="thief">
          <div class="accordion-item">
            <h2 class="accordion-header d-flex align-items-center justify-content-between">
              <button class="accordion-button collapsed upmenu-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#thiefCollapse" aria-expanded="false" aria-controls="act-collapse">
                <div class="menu-list d-flex align-items-center">
                  <span class="icon pages-icon d-flex align-items-center justify-content-center me-3"><img
                      src="../assets/images/icons/copy-2.svg" alt="pages-icon" /></span>
                  <span class="menu-link"> Çalıntı / Kontrol </span>
                </div>
              </button>
            </h2>
            <div id="thiefCollapse" class="accordion-collapse collapse pages-menu" data-bs-parent="#pages">
              <ul>
               
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/check-serial" class="menu-link">
                    Seri Numarası Sorgula
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link to="/check-serial/create" class="menu-link">
                    Çalıntı İhbar Ekle
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush" id="settings">
          <div class="accordion-item">
            <h2 class="accordion-header d-flex align-items-center justify-content-between">
              <button class="accordion-button collapsed upmenu-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#settingsCollapse" aria-expanded="false" aria-controls="act-collapse">
                <div class="menu-list d-flex align-items-center">
                  <span class="icon settings-icon d-flex align-items-center justify-content-center me-3"><img
                      src="../assets/images/icons/Setting.svg" alt="settings-icon" /></span>
                  <span class="menu-link"> Ayarlar </span>
                </div>
              </button>
            </h2>
            <div id="settingsCollapse" class="accordion-collapse collapse pages-menu" data-bs-parent="#settings">
              <ul class="settings-menu">
                <li class="menu-list d-flex align-items-center">
                  <router-link :to="{name: 'Profile'}" class="menu-link">
                    Profil
                  </router-link>
                </li>
                <li class="menu-list d-flex align-items-center">
                  <router-link :to="{name: 'ChangePassword'}" class="menu-link">
                    Şifre İşlemleri
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

        </div>
        <div class="mix-menu" @click="logout">
          <ul>
            <li class="menu-list d-flex align-items-center">
              <span class="icon settings-icon d-flex align-items-center justify-content-center me-3 text-white">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
              </span>
              <button type="button" class="menu-link"> Çıkış Yap </button>
            </li>
          </ul>
        </div>

      </div>
      <a class="add-user d-flex align-items-center">
        <img src="../assets/images/icons/+.svg" alt="+ icon" />
        <span class="addNew-user">Yeni Kullanıcı Davet Et</span>
      </a>

    </aside>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'LeftSideComponent',
  setup() {
    const store = useStore();
    const user = store.getters['getUser'];


    const logout = () => {
      store.dispatch('logout');
      router.push('/login');
    };

    return {
      user,
      logout
    };
  }
});
</script>

<style scoped>
.companyLogo{
  width: 50px;
  height: 50px;
}

</style>
