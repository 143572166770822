export const CheckSerialRoutes = [
  {
    path: "/check-serial",
    name: "CheckSerial",
    component: () => import("@/views/CheckSerial/CheckSerial.vue"),
  },
  {
    path: "/check-serial/create",
    name: "CheckSerialCreate",
    component: () => import("@/views/CheckSerial/CreateSerial.vue"),
  },
];
